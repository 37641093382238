@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "Museo";
		src: url("./static/fonts/Museo700-Regular-webfont.woff2") format("woff2"),
			url("./static/fonts/Museo700-Regular-webfont.woff") format("woff");
		font-weight: 700;
		font-style: normal;
	}

	@font-face {
		font-family: "Museo";
		src: url("./static/fonts/Museo500-Regular-webfont.woff2") format("woff2"),
			url("./static/fonts/Museo500-Regular-webfont.woff") format("woff");
		font-weight: 500;
		font-style: normal;
	}

	@font-face {
		font-family: "Museo";
		src: url("./static/fonts/Museo300-Regular-webfont.woff2") format("woff2"),
			url("./static/fonts/Museo300-Regular-webfont.woff") format("woff");
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: "Museo";
		src: url("./static/fonts/Museo900-Regular.woff2") format("woff2"),
			url("./static/fonts/Museo900-Regular.woff") format("woff");
		font-weight: 900;
		font-style: normal;
	}

	@font-face {
		font-family: "Museo";
		src: url("./static/fonts/Museo100-Regular.woff2") format("woff2"),
			url("./static/fonts/Museo100-Regular.woff") format("woff");
		font-weight: 100;
		font-style: normal;
	}

	body {
		@apply bg-gray-50;
	}

	.map-container {
		@apply h-[25rem] w-auto xl:w-[50rem] mt-5;
	}

	.contact .map-container {
		@apply h-[40rem] w-full;
	}

	::-webkit-scrollbar {
		@apply w-[.5rem] h-[.5rem];
	}

	::-webkit-scrollbar-track {
		@apply bg-slate-50;
	}

	::-webkit-scrollbar-thumb {
		@apply bg-highblu rounded-full transition-all duration-150 ease-in-out border-solid border border-slate-50;
	}

	::-webkit-scrollbar-thumb:hover {
		@apply bg-highor rounded-full transition-all duration-150 ease-in-out border-solid border border-slate-50;
	}
}